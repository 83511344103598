<template>
    <div class='login-body'>
        <Toast />
        <div class='login-wrapper'>
            <div class='login-panel'>
                <img src='assets/layout/images/logo-dark.svg' class='logo' alt='diamond-layout' />

                <div class='login-form'>
                    <h2>Usa le tue credenziali CMS</h2>
                    <Button type='button' class='p-button-outlined p-button-info mr-2 mb-2'
                            @click='loginWithGoogle'>
                        <img alt='Google logo'
                             src='https://4books.com/static/nanabianca/images/icons/Google.svg'
                             style='width: 2.5rem' />
                        <span class='ml-4 font-bold'>Entra con Google</span>
                    </Button>
                </div>

                <p></p>
            </div>
            <div class='login-image'>
                <div class='login-image-content'>
                    <h1>Accedi all'area riservata</h1>
                    <h3>In caso di account multipli si prega di utilizzare quello @4books.com</h3>
                </div>
                <div class='image-footer'>
                    <p>Lasciate ogni speranza voi che entrate.</p>
                    <div class='icons'>
                        <i class='pi pi-exclamation-triangle'></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import http from '../../libs/http';
import tools from '../../libs/tools';
import { mapActions, mapMutations } from 'vuex';
import Notifications from '../../mixins/notifications';

export default {
    mixins: [
        Notifications
    ],
    methods: {
        ...mapActions('Authn', ['login']),
        ...mapMutations('Authn', ['setUserData']),
        loginWithGoogle() {
            http.loginWithGoogle()
                .then(res => {
                    if (res && res['user'] && res['user']['accessToken']){
                        this.login(res['user']['accessToken'])
                            .then(() => {
                                if (res['user']['photoURL']) {
                                    this.setUserData({
                                        email: res['user']['email'],
                                        displayName: res['user']['displayName']
                                    });
                                    tools.imageToBase64(res['user']['photoURL'], photo => {
                                        this.setUserData({
                                            email: res['user']['email'],
                                            displayName: res['user']['displayName'],
                                            photo: photo
                                        });
                                    })
                                } else {
                                    this.setUserData({
                                        email: res['user']['email'],
                                        displayName: res['user']['displayName']
                                    });
                                }

                                this.$router.push({ name: 'dashboard' });
                            })
                            .catch(err => {
                                if (err.error === 'response-mismatch'){
                                    this.ShowError('Errore', 'L\'API ha fornito una risposta non valida, contattare il supporto');
                                } else {
                                    this.ShowError('Errore', 'Login fallito. State usando le giuste credenziali?');
                                }
                            });
                    } else {
                        this.ShowError('Errore', 'C\'è stato un problema con l\'autenticazione su Firebase');
                    }
                })
                .catch(() => {
                    this.ShowError('Errore', "Non è stato possibile contattare Firebase");
                });
        },
    },
};
</script>
